import React, { Fragment, FunctionComponent, Suspense, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { ParsedQuery } from 'query-string';
import { authSelector } from './store/auth/selectors';
import useAuth from './domain/useAuth';
import useQuery from './utils/query';
import useBrand from './domain/useBrand';
import useIntegration from './domain/useIntegration';
import useCurrentUser from './utils/hooks/useCurrentUser';
import { useCampaignListBackground } from './utils/hooks/useCampaignListBackground';
import useDefaultBrandSettingsModal from './containers/defaultBrandSettings';
import WhiteHeader from './containers/header/white';

const Chat = React.lazy(() => import('./containers/chat'));
const CreateBrand = React.lazy(() => import('./pages/createBrand'));
const NewCampaign = React.lazy(() => import('./pages/campaign/new'));
const CampaignsList = React.lazy(() => import('./pages/campaign/list'));
const Campaign = React.lazy(() => import('./pages/campaign/show'));
const AnalyticsList = React.lazy(() => import('./pages/analytics/list'));
const Analytics = React.lazy(() => import('./pages/analytics/show'));
const Settings = React.lazy(() => import('./pages/settings'));
const Login = React.lazy(() => import('./pages/auth/login'));
const Forgot = React.lazy(() => import('./pages/auth/forgot'));
const Reset = React.lazy(() => import('./pages/auth/reset'));
const SignUp = React.lazy(() => import('./pages/auth/register'));
const AdminPanel = React.lazy(() => import('./pages/admin'));
const NotFound = React.lazy(() => import('./pages/notfound'));
const Forbidden = React.lazy(() => import('./pages/forbidden'));
const Expired = React.lazy(() => import('./pages/expired'));
const Join = React.lazy(() => import('./pages/auth/join'));
const Academy = React.lazy(() => import('./pages/academy'));
const NoAccessPage = React.lazy(() => import('./containers/noAccessPage'));
const TwoFactorAuthentication = React.lazy(() => import('./pages/auth/2faForm'));

const Routes: FunctionComponent = () => {
  const { authorized, user, activeBrandId, activeBrand } = useSelector(authSelector);
  const query: ParsedQuery = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { me, setExpiredSubscriptionDate } = useAuth();
  const { withoutBrands, isAdmin, hasToPay, account } = useCurrentUser();
  const { getBrandById } = useBrand();
  const { confirmBrandHubSpotConnection, onSetHubSpotCode } = useIntegration();
  useCampaignListBackground();
  useDefaultBrandSettingsModal();

  const authRoutes = useMemo(
    () => ['/login', '/join', '/forgot-password', '/reset-password', '/register', '/two-factor'],
    []
  );

  useEffect(() => {
    if (query.code && window.location.pathname === '/settings/integrations/hubspot-redirect') {
      onSetHubSpotCode(query.code as string);
    }
  }, [query.code]);

  useEffect(() => {
    if (query.expiredAt && user.id) {
      setExpiredSubscriptionDate(String(query.expiredAt));
      notification.success({
        message: 'Payment info',
        description: 'Payment was successfully accepted'
      });
    }
    if (query.successPayment) {
      history.push('/settings/subscription');
    }
  }, [user.id]);

  useEffect(
    () => {
      if (authorized && !user.id) me();
      if (!authorized && !authRoutes.includes(location.pathname)) {
        history.push('/login', { from: location.pathname });
      }
    }, // eslint-disable-next-line
    [authorized]
  );

  useEffect(() => {
    if (authorized && user.id && activeBrandId && !activeBrand.id && !isAdmin) {
      getBrandById(activeBrandId);
      confirmBrandHubSpotConnection();
    }
  }, [authorized, user.id, Number(activeBrandId)]);

  return (
    <Suspense fallback={() => <div>loading...</div>}>
      {authorized && user.id && (
        <>
          <WhiteHeader />
          <Chat />
        </>
      )}
      <Switch>
        {authorized && user.id ? (
          <Fragment>
            {hasToPay && <Redirect from="*" to="/custom-expired" />}
            {withoutBrands && !isAdmin ? <Redirect from="*" to="/create-brand" /> : null}
            <Switch>
              <Route path="/custom-expired" exact component={Expired} />
              <Route path="/create-brand" exact component={CreateBrand} />
              <Route path="/campaigns" exact component={CampaignsList} />
              <Route path="/create-campaign" exact component={NewCampaign} />
              <Route path="/campaigns/:id" component={Campaign} />

              <Route path="/academy" exact component={Academy} />

              <Route path="/analytics" exact component={AnalyticsList} />
              <Route path="/analytics/:id" component={Analytics} />
              <Route path="/settings" component={Settings} />
              <Route path="/admin" component={AdminPanel} />
              <Route path="/no-access" exact component={NoAccessPage} />
              <Route path="/403" component={Forbidden} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Fragment>
        ) : null}

        <Route path="/join" exact component={Join} />
        <Route path="/login" exact component={Login} />
        <Route path="/forgot-password" exact component={Forgot} />
        <Route path="/reset-password" exact component={Reset} />
        <Route path="/register" exact component={SignUp} />
        <Route path="/two-factor" exact component={TwoFactorAuthentication} />
        {authorized ? <Redirect from="/" to="/campaigns" exact /> : <Redirect from="/" to="/login" exact />}
      </Switch>
    </Suspense>
  );
};

export default Routes;
