import I, { ImmutableObject } from 'seamless-immutable';
import { CampaignStoreEnum } from './types';
import { clearList } from '../admin/adapters';
import { CampaignAssignmentTypeEnum } from '../../utils/enums';
import {
  Campaign,
  CampaignBrand,
  CampaignGame,
  CampaignStatistics,
  CampaignStatus,
  EmailTemplate,
  GameType,
  LandingPageElement,
  LandingPageScreenElement,
  PersonalityResult,
  Prize,
  PrizeCode,
  PrizeWinner,
  RelatedCampaign,
  UpdatedNowBy,
  UploadedContent
} from '../../utils/type';
import { Pagination } from '../types';

export const storeAdapterMapping: Record<any, (data: any) => ImmutableObject<any>> = {
  [CampaignStoreEnum.PLAYER_UPLOADED_CONTENT]: uploadedContentAdapter,
  [CampaignStoreEnum.DEFAULT_UPLOADED_CONTENT]: uploadedContentAdapter
};

export function prizesAdapter(data: Prize[]) {
  return I(data.map(prizeAdapter));
}

export function prizeAdapter(data: Prize) {
  return I({
    _id: data._id,
    prizeType: data.prizeType,
    title: data.title,
    description: data.description,
    assignedPrizeCount: data.assignedPrizeCount || 0,
    image: data.image,
    count: data.count,
    remaining: data.remaining,
    default: data.default,
    winning_criteria_value: data.winning_criteria_value,
    winning_criteria_type: data.winning_criteria_type,
    raffling_start_date: data.raffling_start_date,
    raffling_end_date: data.raffling_end_date,
    hasLimit: data.hasLimit,
    limit: data.limit,
    hourForLimitUsedReset: data.hourForLimitUsedReset,
    notifyOnWin: data.notifyOnWin,
    useSingleCode: data.useSingleCode,
    prizeCode: data.prizeCode,
    assigmentPerPlayer: data.assigmentPerPlayer,
    unlimitedAssigment: data.unlimitedAssigment,
    extraWinningProbability: data.extraWinningProbability,
    unlimitedCount: data.unlimitedCount,
    winningStringValue: data.winningStringValue
  });
}

function campaignStatusAdapter(data?: CampaignStatus) {
  return I({
    id: data?.id || null,
    title: data?.title
  });
}

export function landingPageElementsAdapter(data?: LandingPageElement[]) {
  if (!data) return I([]);
  return I(
    data.map((item: LandingPageElement) =>
      I({
        id: item.id,
        params: item.params,
        title: item.title
      })
    )
  );
}

export function landingScreenElementAdapter(data?: LandingPageScreenElement) {
  return I({
    element_params: data?.element_params || '{}',
    id: data?.id,
    prizeId: data?.prizeId || null,
    landingPageElement: data?.landingPageElement,
    priority: data?.priority,
    screenType: data?.screenType,
    columnId: data?.columnId,
    adventCalendarDays: data?.adventCalendarDays
  });
}

export function landingScreenElementsAdapter(data?: LandingPageScreenElement[]) {
  if (data?.length) return I(data.map(landingScreenElementAdapter));
  return I([]);
}

function campaignGameTypeAdapter(data?: GameType) {
  return I({
    id: data?.id || null,
    image: data?.image,
    title: data?.title,
    type: data?.type
  });
}

function campaignGameAdapter(data?: CampaignGame) {
  return I({
    description: data?.description,
    id: data?.id || null,
    settings: data?.settings,
    settings_type: data?.settings_type || '',
    title: data?.title,
    image: data?.image || '',
    gameType: campaignGameTypeAdapter(data?.gameType),
    guide_file: data?.guide_file || ''
  });
}

function campaignBrandAdapter(data?: CampaignBrand) {
  return I({
    created_at: data?.created_at,
    desktop_styles: data?.desktop_styles,
    id: data?.id || null,
    mobile_styles: data?.mobile_styles,
    title: data?.title,
    slug: data?.slug,
    fonts: data?.fonts || []
  });
}

export function emailTemplateAdapter(data: EmailTemplate) {
  return I({
    _id: data._id,
    campaignId: data.campaignId,
    subject: data.subject,
    text: data.text,
    type: data.type,
    automaticSending: data.automaticSending,
    senderName: data.senderName,
    prizeIds: data.prizeIds || null,
    active: data.active,
    sent: data.sent,
    opened: data.opened,
    delivered: data.delivered,
    uniqueClicked: data.uniqueClicked,
    uniqueOpened: data.uniqueOpened,
    sendingDate: data.sendingDate
  });
}

export function emailTemplatesAdapter(data?: EmailTemplate[]) {
  if (data) {
    return I(data.map(emailTemplateAdapter));
  }
  return I([]);
}

export function updatedNowByAdapter(data?: UpdatedNowBy) {
  return I({
    email: data?.email || '',
    fullName: data?.fullName || '',
    role: data?.role || ''
  });
}

export function campaignAdapter(data?: Campaign) {
  return I({
    ...data,
    created_at: data?.created_at,
    language: data?.language,
    connectedCampaignId: data?.connectedCampaignId,
    connectedCampaignsCount: data?.connectedCampaignsCount || 0,
    integrationSegmentId: data?.integrationSegmentId,
    desktop_styles: data?.desktop_styles,
    animated_background_config: data?.animated_background_config || '{}',
    originalCampaignId: data?.originalCampaignId || null,
    translatedCampaignsCount: data?.translatedCampaignsCount || 0,
    email_notification: data?.email_notification || false,
    end_date: data?.end_date,
    head_script: data?.head_script || '',
    body_script: data?.body_script || '',
    background_config: data?.background_config || '{}',
    content_config: data?.content_config || '{}',
    screens_setup: data?.screens_setup,
    enablePrizeSpecificScreens: data?.enablePrizeSpecificScreens,
    enablePrizeSpecificEmailTemplates: data?.enablePrizeSpecificEmailTemplates,
    automatically_start: data?.automatically_start,
    emailTemplates: emailTemplatesAdapter(data?.emailTemplates),
    game_config: data?.game_config || '{}',
    assigment_type: data?.assigment_type || CampaignAssignmentTypeEnum.SINGLE_OPT,
    notifications_config: data?.notifications_config || '{}',
    // game_config: JSON.stringify(mockgame),
    game_area_config: data?.game_area_config || '{}',
    id: data?.id || null,
    account: data?.account || {},
    favicon: data?.favicon,
    html_title: data?.html_title,
    apiKey: data?.apiKey,
    game_limit_type: data?.game_limit_type,
    tags: data?.tags || [],
    meta_description: data?.meta_description,
    meta_image: data?.meta_image,
    meta_title: data?.meta_title,
    mobile_styles: data?.mobile_styles,
    completedCampaignScreensSetup: Boolean(data?.completedCampaignScreensSetup),
    updatedNowBy: updatedNowByAdapter(data?.updatedNowBy),
    slug: data?.slug,
    start_date: data?.start_date,
    terms_and_conditions: data?.terms_and_conditions,
    terms_and_conditions_config: data?.terms_and_conditions_config || '{}',
    privacy_policy_config: data?.privacy_policy_config || '{}',
    errors_config: data?.errors_config || '{}',
    leaderboard_config: data?.leaderboard_config || '{}',
    embed_options_config: data?.embed_options_config || '{}',
    side_bar_config: data?.side_bar_config || '{}',
    title: data?.title,
    updated_at: data?.updated_at,
    brand: campaignBrandAdapter(data?.brand),
    landingPageScreenElements: landingScreenElementsAdapter(data?.landingPageScreenElements),
    status: campaignStatusAdapter(data?.status),
    game: campaignGameAdapter(data?.game),
    uniqueLeadCount: data?.uniqueLeadCount || 0,
    brandEmailId: data?.brandEmailId || null,
    gameDurationTotal: data?.gameDurationTotal || 0,
    gameEndedCount: data?.gameEndedCount || 0,
    gameStartedCount: data?.gameStartedCount || 0,
    gameplayCount: data?.gameplayCount || 0,
    gameplayFinishedCount: data?.gameplayFinishedCount || 0,
    uniqueVisitorCount: data?.uniqueVisitorCount || 0,
    visitorCount: data?.visitorCount || 0,
    published_at: data?.published_at,
    hubSpotConnectionId: data?.hubSpotConnectionId || null,
    mailChimpConnectionId: data?.mailChimpConnectionId || null,
    salesforceConnectionId: data?.salesforceConnectionId || null,
    integrations_config: data?.integrations_config || '{}',
    emailRestriction: data?.emailRestriction || null,
    prize_added: Boolean(data?.prize_added),
    sharedLeaderboardCampaignId: data?.sharedLeaderboardCampaignId,
    sharedLeaderboardSettings: data?.sharedLeaderboardSettings || null,
    folderId: data?.folderId || null,
    send_webhooks: data?.send_webhooks,
    locked: Boolean(data?.locked)
  });
}

export function winnerAdapter(data: PrizeWinner) {
  return I({
    player: {
      email: data.playerInfo?.email,
      fullName: data.playerInfo?.fullName,
      firstName: data.playerInfo?.firstName,
      lastName: data.playerInfo?.lastName,
      phoneNumber: data.playerInfo?.phoneNumber
    },
    prize: data.prize,
    prizeTitle: data.prizeTitle,
    code: data.code,
    createdAt: data.createdAt,
    emailSendStatus: data.emailSendStatus,
    rafflingHistory: data.rafflingHistory,
    _id: data._id,
    clicked: Number(data.clicked),
    templateId: data.templateId,
    clickedDate: data.clickedDate,
    link: data.link,
    opened: Number(data.opened),
    clicks: Number(data.clicks),
    template: {
      subject: data.template?.subject
    },
    sentAt: data.sentAt,
    openedAt: data.openedAt
  });
}

export function winnersAdapter(data: Pagination<PrizeWinner>) {
  return I({
    meta: {
      currentPage: data.meta.currentPage,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalItems: data.meta.totalItems,
      totalPages: data.meta.totalPages
    },
    items: data.items.map(winnerAdapter)
  });
}

export function prizeCodeAdapter(data: PrizeCode) {
  return I({
    code: data.code,
    isUsed: data.isUsed,
    prize: data.prize,
    _id: data._id
  });
}

export function resultAdapter(data: PersonalityResult) {
  return I({
    name: data.name,
    image: data.image,
    redirect: data.redirect,
    href: data.href,
    description: data.description,
    use_background: data.use_background,
    background: data.background
  });
}

export function relatedCampaignAdapter(data: RelatedCampaign) {
  return I({
    id: data.id,
    slug: data.slug,
    language: data.language,
    title: data.title
  });
}

export function uploadedContentAdapter(data: UploadedContent) {
  return I({
    _id: data._id,
    imageSrc: data.imageSrc,
    score: data.score,
    title: data.title,
    description: data.description,
    approved: data.approved
  });
}

export function campaignStatisticsAdapter(data: CampaignStatistics) {
  return I({
    uniqueLeadCount: data?.uniqueLeadCount || 0,
    gameDurationTotal: data?.gameDurationTotal || 0,
    gameEndedCount: data?.gameEndedCount || 0,
    gameStartedCount: data?.gameStartedCount || 0,
    gameplayCount: data?.gameplayCount || 0,
    gameplayFinishedCount: data?.gameplayFinishedCount || 0,
    uniqueVisitorCount: data?.uniqueVisitorCount || 0,
    visitorCount: data?.visitorCount || 0,
    campaign: campaignAdapter(data?.campaign)
  });
}

export function relatedCampaignsAdapter(data: RelatedCampaign[]) {
  return I(data.map(relatedCampaignAdapter));
}

export function campaignStoreAdapter(data?: Campaign) {
  return I({
    active: campaignAdapter(data),
    activeS3: campaignAdapter(data),
    activeElement: null,
    relatedCampaigns: relatedCampaignsAdapter([]),
    landingPageElements: landingPageElementsAdapter(),
    history: [],
    winners: I(clearList()),
    prizes: I([]),
    prizeCodes: clearList(),
    emailTemplates: I([]),
    inputList: [],
    editingTemplate: false,
    [CampaignStoreEnum.PLAYER_UPLOADED_CONTENT]: clearList(),
    [CampaignStoreEnum.DEFAULT_UPLOADED_CONTENT]: clearList(),
    [CampaignStoreEnum.LEADERBOARD_CONNECTION_SETTINGS]: []
  });
}
