import { ParsedQuery } from 'query-string';
import useQuery, { queryToString } from '../../query';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const usePageQuery = () => {
  const query: ParsedQuery = useQuery();
  const location = useLocation();
  const history = useHistory();

  const setNewQuery = useCallback(
    (newQuery: Record<string, unknown>) => {
      const newLocation = location.pathname + '?' + queryToString(Object.assign(query, newQuery));
      history.push(newLocation);
    },
    [history, location, query]
  );

  return {
    query,
    setNewQuery,
    history
  };
};
