import { ImmutableObject } from 'seamless-immutable';
import { Pagination } from '../types';
import {
  Campaign,
  ConnectedCampaign,
  EmailTemplate,
  LandingPageElement,
  LandingPageScreenElement,
  Prize,
  PrizeCode,
  PrizeWinner,
  RelatedCampaign,
  UploadedContent
} from '../../utils/type';

export enum CampaignStoreEnum {
  PLAYER_UPLOADED_CONTENT = 'playerUploadedContentList',
  DEFAULT_UPLOADED_CONTENT = 'defaultUploadedContentList',
  LEADERBOARD_CONNECTION_SETTINGS = 'leaderboardConnectionSettings'
}

export type Action = {
  type: string;
  payload: any;
  path?: CampaignStoreEnum;
  compareField?: string;
};

export type CampaignState = ImmutableObject<{
  active: Campaign;
  activeS3: Campaign;
  activeElement: LandingPageScreenElement | null;
  history: Partial<Campaign>[];
  relatedCampaigns: RelatedCampaign[];
  landingPageElements: LandingPageElement[];
  winners: Pagination<PrizeWinner>;
  prizes: Prize[];
  prizeCodes: Pagination<PrizeCode>;
  emailTemplates: EmailTemplate[];
  inputList: string[];
  editingTemplate: boolean;
  [CampaignStoreEnum.PLAYER_UPLOADED_CONTENT]: Pagination<UploadedContent>;
  [CampaignStoreEnum.DEFAULT_UPLOADED_CONTENT]: Pagination<UploadedContent>;
  [CampaignStoreEnum.LEADERBOARD_CONNECTION_SETTINGS]: ConnectedCampaign[];
}>;
